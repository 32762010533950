import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";

import { ArtistTable } from "../Table";
import { Loader } from "../Common";
import { SITE_BACKEND_API_URL } from "../../server";

import { artistFetchCommForTable } from "../../utils/fetchCommForTable";
import { HomeSubmissionContainer, HomeTabArea } from "../SharedStyle/styled";
import NoCommissionsImage from "../../assets/no-commissions.png";
import {
  NoRecordImgWrapper,
  NoRecordImg,
} from "./artist-home.styles";

const TABLE_COLUMNS = [
//   {
//     Header: "Date",
//     accessor: "order_created_at",
//     disableFilters: true,
//   },
  {
    Header: "Product",
    accessor: "product_title",
    filter: "fuzzyText",
  },
//  {
//    Header: "Product Type",
//    accessor: "product_type",
//    Filter: SelectColumnFilter,
//    filter: "equals",
//  },
  {
    Header: "Quantity",
    accessor: "quantity",
    className: 'cell-center',
    disableFilters: true,
  },
  {
    Header: "Paid Amount",
    accessor: "paid_amount",
    className: 'cell-center',
    disableFilters: true,
  },
  {
    Header: "Unpaid Amount",
    accessor: "unpaid_amount",
    className: 'cell-center',
    disableFilters: true,
  },
//   {
//     Header: "Paid or Unpaid",
//     accessor: "is_commissions_paid",
//     Filter: SelectColumnFilter,
//     filter: "includes",
//   },
];

class ArtistCommissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      tableDataLastMonth: [],
      errorMsg: "",
      loading: true,
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  componentDidMount() {
    this.getAllCommissions();
  }

  setTableData = (data) => {
    this.setState({ tableData: data });
  };

  getAllCommissions = async () => {
    try {
      const { token } = this.props;

      const reqBody = {
//        url: "/api/artist/commissions",
        url: SITE_BACKEND_API_URL+"/artist/commissions/list?month=current",
        method: "GET",
      };

      const tableData = await artistFetchCommForTable(reqBody, token);
      this.setState({ tableData });

      const reqBodyLastMonth = {
        url: SITE_BACKEND_API_URL+"/artist/commissions/list",
        method: "GET",
      };

      const tableDataLastMonth = await artistFetchCommForTable(reqBodyLastMonth, token);
      this.setState({ tableDataLastMonth, loading: false });

    } catch (error) {
      console.log(error);
      this.setState({
        errorMsg:
          "We could not find any records. Let us know if its a mistake.",
      });
    }
  };

  handleDateFilter = async ({ startDate, endDate }) => {
    const { token } = this.props;
    const reqBody = {
//      url: "/api/artist/commissions/dates",
      url: SITE_BACKEND_API_URL+"/artist/commissions/list",
      method: "POST",
      data: {
        startDate,
        endDate,
      },
    };
    try {
      const tableData = await artistFetchCommForTable(reqBody, token);

      this.setState({
        tableData,
        startDate,
        endDate,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        errorMsg:
          "We could not find any records. Let us know if its a mistake.",
      });
    }
  };

  getCurrentMonthName = () => {
    const currentDate = new Date();
    const monthName = currentDate.toLocaleString('default', { month: 'long' });

    return monthName.toUpperCase()+ ' ' +currentDate.getFullYear();
  }

  getLasttMonthName = () => {
    const currentDate = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(currentDate.getMonth() - 1);
    const monthName = lastMonth.toLocaleString('default', { month: 'long' });

    return monthName.toUpperCase()+ ' ' +lastMonth.getFullYear();
  }

  render() {
    const { tableData, tableDataLastMonth, errorMsg, loading } = this.state;
    return (
      <HomeSubmissionContainer>
        <HomeTabArea>
          <h4><span style={{color: '#AAA'}}>CURRENT MONTH </span>{ this.getCurrentMonthName() }</h4>
          {tableData.length > 0 ? (
            <ArtistTable className="home-table" columns={TABLE_COLUMNS} data={tableData} />
          ) : errorMsg ? (
            <h2>{errorMsg}</h2>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : (
              <NoRecordImgWrapper>
                <NoRecordImg style={{maxHeight: "350px"}} src={NoCommissionsImage}/>
              </NoRecordImgWrapper>
              )}
            </>
          )}
        </HomeTabArea>

        <HomeTabArea style={{ marginTop: "30px" }}>
          <h4><span style={{color: '#AAA'}}>LAST MONTH </span>{ this.getLasttMonthName() }</h4>
          {tableDataLastMonth.length > 0 ? (
            <ArtistTable className="home-table" columns={TABLE_COLUMNS} data={tableDataLastMonth} />
          ) : errorMsg ? (
            <h2>{errorMsg}</h2>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : (
              <NoRecordImgWrapper>
                <NoRecordImg style={{maxHeight: "350px"}} src={NoCommissionsImage}/>
              </NoRecordImgWrapper>
              )}
            </>
          )}
        </HomeTabArea>
      </HomeSubmissionContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(ArtistCommissions);
